/**
 * Type of nodes in the DOM tree.
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Node/nodeType#node_type_constants Node type constants on MDN}
 */
export var NodeType;
(function (NodeType) {
    NodeType[NodeType["ELEMENT"] = 1] = "ELEMENT";
    NodeType[NodeType["TEXT"] = 3] = "TEXT";
    NodeType[NodeType["PROCESSING_INSTRUCTION"] = 7] = "PROCESSING_INSTRUCTION";
    NodeType[NodeType["CDATA_SECTION"] = 4] = "CDATA_SECTION";
    NodeType[NodeType["DOCUMENT"] = 9] = "DOCUMENT";
    NodeType[NodeType["COMMENT"] = 8] = "COMMENT";
})(NodeType || (NodeType = {}));
