import entitiesData from './gen/entities.json';
import legacyEntitiesData from './gen/legacy-entities.json';
import { createEntityDecoder } from './createEntityDecoder';
import { EntityManager } from './EntityManager';
import { unpackMap } from './unpackMap';
export var htmlEntities = unpackMap(entitiesData);
export var legacyHtmlEntities = unpackMap(legacyEntitiesData);
/**
 * An entity manager that supports HTML entities.
 */
export var htmlEntityManager = new EntityManager();
htmlEntityManager.setAll(htmlEntities);
htmlEntityManager.setAll(legacyHtmlEntities, true);
export var decodeHtml = createEntityDecoder(htmlEntityManager);
