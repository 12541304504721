import { __assign } from "tslib";
/**
 * Clones an arbitrary token.
 */
export function clone() {
    var token = __assign({}, this);
    if (token.tokenType === 1 /* START_TAG */) {
        var attributes = token.attributes = __assign({}, token.attributes);
        for (var i = 0; i < attributes.length; ++i) {
            attributes[i] = __assign({}, attributes[i]);
        }
    }
    return token;
}
export function createStartTagToken() {
    return {
        tokenType: 1 /* START_TAG */,
        name: '',
        rawName: '',
        attributes: { length: 0 },
        selfClosing: false,
        start: 0,
        end: 0,
        nameStart: 0,
        nameEnd: 0,
        clone: clone,
    };
}
export function createEndTagToken() {
    return {
        tokenType: 101 /* END_TAG */,
        name: '',
        rawName: '',
        start: 0,
        end: 0,
        nameStart: 0,
        nameEnd: 0,
        clone: clone,
    };
}
export function createDataToken() {
    return {
        tokenType: 3 /* TEXT */,
        data: '',
        rawData: '',
        start: 0,
        end: 0,
        dataStart: 0,
        dataEnd: 0,
        clone: clone,
    };
}
export function createAttributeToken() {
    return {
        tokenType: 2 /* ATTRIBUTE */,
        name: '',
        rawName: '',
        value: '',
        rawValue: '',
        quoted: false,
        start: 0,
        end: 0,
        nameStart: 0,
        nameEnd: 0,
        valueStart: 0,
        valueEnd: 0,
        clone: clone,
    };
}
