import { fromCharCode } from './misc';
var OFFSET = 0xffff;
/**
 * Unpacks mapping created at build time.
 */
export function unpackMap(data) {
    var unpackedMap = {};
    var tokens = data.split(' ');
    for (var i = 0; i < tokens.length; i += 2) {
        unpackedMap[tokens[i]] = unpackValue(tokens[i + 1]);
    }
    return unpackedMap;
}
function unpackValue(data) {
    var value = '';
    var packedValue = parseInt(data, 36);
    while (packedValue > 0) {
        value += fromCharCode(packedValue % OFFSET);
        packedValue = (packedValue - packedValue % OFFSET) / OFFSET;
    }
    return value;
}
