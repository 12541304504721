import { __assign } from "tslib";
import { createSaxParser } from './createSaxParser';
import { decodeXml } from 'speedy-entities';
/**
 * Creates a pre-configured XML SAX parser.
 *
 * @param handler The parsing handler.
 * @param options Options that override the defaults.
 * @see {@link xmlParserOptions}
 */
export function createXmlSaxParser(handler, options) {
    return createSaxParser(handler, __assign(__assign({}, xmlParserOptions), options));
}
/**
 * The default XML parser options:
 * - CDATA sections, processing instructions and self-closing tags are recognized;
 * - XML entities are decoded in text and attribute values;
 * - Tag and attribute names are preserved as is;
 *
 * @see {@link https://github.com/smikhalevski/speedy-entities decodeXml}
 */
export var xmlParserOptions = {
    cdataEnabled: true,
    processingInstructionsEnabled: true,
    selfClosingEnabled: true,
    decodeText: decodeXml,
    decodeAttribute: decodeXml,
};
