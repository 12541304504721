import { __assign } from "tslib";
import { createDomParser } from './createDomParser';
import { xmlParserOptions } from './createXmlSaxParser';
export function createXmlDomParser(handler, options) {
    return createDomParser(handler || domHandler, __assign(__assign({}, xmlParserOptions), options));
}
/**
 * The default DOM handler.
 */
export var domHandler = {
    element: function (token) {
        var attributes = Object.create(null);
        for (var i = 0; i < token.attributes.length; i++) {
            var attribute = token.attributes[i];
            attributes[attribute.name] = attribute.value;
        }
        return {
            nodeType: 1 /* ELEMENT */,
            parent: null,
            tagName: token.name,
            attributes: attributes,
            selfClosing: token.selfClosing,
            children: [],
            start: token.start,
            end: token.end,
        };
    },
    appendChild: function (parentNode, node) {
        node.parent = parentNode;
        parentNode.children.push(node);
    },
    containerEnd: function (node, token) {
        node.end = token.end;
    },
    document: function (token) {
        return {
            nodeType: 9 /* DOCUMENT */,
            parent: null,
            doctype: token.data,
            children: [],
            start: token.start,
            end: token.end,
        };
    },
    text: function (token) { return createDataNode(3 /* TEXT */, token); },
    processingInstruction: function (token) { return createDataNode(7 /* PROCESSING_INSTRUCTION */, token); },
    cdata: function (token) { return createDataNode(4 /* CDATA_SECTION */, token); },
    comment: function (token) { return createDataNode(8 /* COMMENT */, token); },
};
function createDataNode(nodeType, token) {
    return {
        nodeType: nodeType,
        data: token.data,
        parent: null,
        start: token.start,
        end: token.end,
    };
}
