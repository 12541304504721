/**
 * The type of the token produced by the SAX parser.
 */
export var TokenType;
(function (TokenType) {
    TokenType[TokenType["START_TAG"] = 1] = "START_TAG";
    TokenType[TokenType["END_TAG"] = 101] = "END_TAG";
    TokenType[TokenType["ATTRIBUTE"] = 2] = "ATTRIBUTE";
    TokenType[TokenType["DOCTYPE"] = 10] = "DOCTYPE";
    TokenType[TokenType["TEXT"] = 3] = "TEXT";
    TokenType[TokenType["CDATA_SECTION"] = 4] = "CDATA_SECTION";
    TokenType[TokenType["PROCESSING_INSTRUCTION"] = 7] = "PROCESSING_INSTRUCTION";
    TokenType[TokenType["COMMENT"] = 8] = "COMMENT";
})(TokenType || (TokenType = {}));
