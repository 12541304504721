import { fromCodePoint } from './fromCodePoint';
/**
 * Creates an entity decoder that rewrites numeric and named entities found in input to their respective values.
 *
 * @param entityManager An entity manager that defines named entities.
 * @param options The decoder options.
 */
export function createEntityDecoder(entityManager, options) {
    options || (options = {});
    var _a = options.numericCharacterReferenceTerminated, numericCharacterReferenceTerminated = _a === void 0 ? false : _a, _b = options.illegalCodePointsForbidden, illegalCodePointsForbidden = _b === void 0 ? false : _b, _c = options.replacementChar, replacementChar = _c === void 0 ? '\ufffd' : _c;
    return function (input) {
        var output = '';
        var textIndex = 0;
        var charIndex = 0;
        var inputLength = input.length;
        while (charIndex < inputLength) {
            var startIndex = input.indexOf('&', charIndex);
            if (startIndex === -1) {
                break;
            }
            charIndex = startIndex;
            var entityValue = void 0;
            var endIndex = void 0;
            // Numeric character reference
            if (input.charCodeAt(++startIndex) === 35 /* # */) {
                var radixCharCode = input.charCodeAt(++startIndex);
                var charCode = void 0;
                var digitCount = 0;
                var codePoint = 0;
                if (radixCharCode === 120 /* x */ || radixCharCode === 88 /* X */) {
                    endIndex = ++startIndex;
                    // Parse hexadecimal
                    while (digitCount < 6 && endIndex < inputLength) {
                        charCode = input.charCodeAt(endIndex);
                        if (charCode >= 48 /* 0 */ && charCode <= 57 /* 9 */) {
                            codePoint = codePoint * 16 + (charCode - (charCode & 112));
                            ++digitCount;
                            ++endIndex;
                        }
                        else if (charCode >= 97 /* a */ && charCode <= 102 /* f */ || charCode >= 65 /* A */ && charCode <= 70 /* F */) {
                            codePoint = codePoint * 16 + (charCode - (charCode & 112)) + 9;
                            ++digitCount;
                            ++endIndex;
                        }
                        else {
                            break;
                        }
                    }
                }
                else {
                    endIndex = startIndex;
                    // Parse decimal
                    while (digitCount < 6 && endIndex < inputLength) {
                        charCode = input.charCodeAt(endIndex);
                        if (charCode >= 48 /* 0 */ && charCode <= 57 /* 9 */) {
                            codePoint = codePoint * 10 + (charCode - (charCode & 112));
                            ++digitCount;
                            ++endIndex;
                        }
                        else {
                            break;
                        }
                    }
                }
                if (digitCount >= 2) {
                    var terminated = input.charCodeAt(endIndex) === 59 /* ; */;
                    if (terminated || !numericCharacterReferenceTerminated) {
                        entityValue = fromCodePoint(codePoint, replacementChar, illegalCodePointsForbidden);
                    }
                    if (terminated) {
                        ++endIndex;
                    }
                }
            }
            else {
                // Named character reference
                endIndex = startIndex;
                var entity = entityManager.search(input, startIndex);
                if (entity != null) {
                    endIndex += entity.name.length;
                    var terminated = input.charCodeAt(endIndex) === 59 /* ; */;
                    if (terminated) {
                        ++endIndex;
                    }
                    if (terminated || entity.legacy) {
                        entityValue = entity.value;
                    }
                }
            }
            if (entityValue != null) {
                output += textIndex !== charIndex ? input.substring(textIndex, charIndex) + entityValue : entityValue;
                textIndex = endIndex;
            }
            charIndex = endIndex;
        }
        if (textIndex === 0) {
            return input;
        }
        if (textIndex !== inputLength) {
            output += input.substring(textIndex);
        }
        return output;
    };
}
