import { createTrieNode, searchTrie, setTrie } from './trie-utils';
var TrieMap = /** @class */ (function () {
    function TrieMap(entries) {
        this.rootNode = createTrieNode();
        if (entries != null) {
            this.setAll(entries);
        }
    }
    TrieMap.prototype.set = function (key, value) {
        setTrie(this.rootNode, key, value);
    };
    TrieMap.prototype.setAll = function (entries) {
        for (var _i = 0, entries_1 = entries; _i < entries_1.length; _i++) {
            var _a = entries_1[_i], key = _a[0], value = _a[1];
            setTrie(this.rootNode, key, value);
        }
    };
    TrieMap.prototype.get = function (key) {
        var _a;
        return (_a = this.search(key, 0)) === null || _a === void 0 ? void 0 : _a.value;
    };
    TrieMap.prototype.search = function (input, offset, charCodeAt) {
        if (offset === void 0) { offset = 0; }
        return searchTrie(this.rootNode, input, offset, charCodeAt);
    };
    return TrieMap;
}());
export { TrieMap };
