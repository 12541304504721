import { createTrieNode, searchTrie, setTrie } from '@smikhalevski/trie';
var EntityManager = /** @class */ (function () {
    function EntityManager() {
        this._trie = createTrieNode();
    }
    /**
     * Sets a new mapping from entity name to a replacement value.
     *
     * @param name The entity name.
     * @param value The value that the entity reference is replaced with.
     * @param legacy If `true` then entity reference doesn't require a trailing semicolon.
     */
    EntityManager.prototype.set = function (name, value, legacy) {
        return setTrie(this._trie, name, { name: name, value: value, legacy: legacy || false });
    };
    /**
     * Sets multiple entity mappings.
     *
     * @param entities The map from an entity name to a value.
     * @param legacy If `true` then entity reference doesn't require a trailing semicolon.
     */
    EntityManager.prototype.setAll = function (entities, legacy) {
        for (var _i = 0, _a = Object.entries(entities); _i < _a.length; _i++) {
            var _b = _a[_i], name_1 = _b[0], value = _b[1];
            this.set(name_1, value, legacy);
        }
    };
    /**
     * Searches an entity name in the `input` string staring from `offset`.
     *
     * ```ts
     * const entityManager = new EntityManager();
     *
     * entityManager.set('foo', 'bar');
     *
     * entityManager.search('__foo__', 2);
     * // → {key: "foo", value: "bar", legacy: false}
     * ```
     *
     * @param input The string to search entity names in.
     * @param offset The offset in the `input` to start searching from.
     */
    EntityManager.prototype.search = function (input, offset) {
        var _a;
        return (_a = searchTrie(this._trie, input, offset)) === null || _a === void 0 ? void 0 : _a.value;
    };
    return EntityManager;
}());
export { EntityManager };
