import { createEntityDecoder } from './createEntityDecoder';
import { EntityManager } from './EntityManager';
export var xmlEntities = {
    amp: '&',
    apos: '\'',
    gt: '>',
    lt: '<',
    quot: '"',
};
/**
 * An entity manager that supports XML entities.
 */
export var xmlEntityManager = new EntityManager();
xmlEntityManager.setAll(xmlEntities);
/**
 * Decodes XML entities in a string.
 */
export var decodeXml = createEntityDecoder(xmlEntityManager, { numericCharacterReferenceTerminated: true });
