import { Collection, Data, Domain, Helpers, LabelHelpers, Scale } from "victory-core";
export var getBarPosition = function (props, datum) {
  var getDefaultMin = function (axis) {
    var defaultZero = Scale.getType(props.scale[axis]) === "log" ? 1 / Number.MAX_SAFE_INTEGER : 0;
    var defaultMin = defaultZero;
    var minY = Collection.getMinValue(props.domain[axis]);
    var maxY = Collection.getMaxValue(props.domain[axis]);

    if (minY < 0 && maxY <= 0) {
      defaultMin = maxY;
    } else if (minY >= 0 && maxY > 0) {
      defaultMin = minY;
    }

    return datum["_".concat(axis)] instanceof Date ? new Date(defaultMin) : defaultMin;
  };

  var _y0 = datum._y0 !== undefined ? datum._y0 : getDefaultMin("y");

  var _x0 = datum._x0 !== undefined ? datum._x0 : getDefaultMin("x");

  return Helpers.scalePoint(props, Object.assign({}, datum, {
    _y0: _y0,
    _x0: _x0
  }));
};

var getCalculatedValues = function (props) {
  var polar = props.polar;
  var defaultStyles = Helpers.getDefaultStyles(props, "bar");
  var style = !props.disableInlineStyles ? Helpers.getStyles(props.style, defaultStyles) : {};
  var range = props.range || {
    x: Helpers.getRange(props, "x"),
    y: Helpers.getRange(props, "y")
  };
  var domain = {
    x: Domain.getDomainWithZero(props, "x"),
    y: Domain.getDomainWithZero(props, "y")
  };
  var scale = {
    x: Scale.getBaseScale(props, "x").domain(domain.x).range(props.horizontal ? range.y : range.x),
    y: Scale.getBaseScale(props, "y").domain(domain.y).range(props.horizontal ? range.x : range.y)
  };
  var origin = polar ? props.origin || Helpers.getPolarOrigin(props) : undefined;
  var data = Data.getData(props);
  data = Data.formatDataFromDomain(data, domain, 0);
  return {
    style: style,
    data: data,
    scale: scale,
    domain: domain,
    origin: origin
  };
};

export var getBaseProps = function (initialProps, fallbackProps) {
  var modifiedProps = Helpers.modifyProps(initialProps, fallbackProps, "bar");
  var props = Object.assign({}, modifiedProps, getCalculatedValues(modifiedProps));
  var alignment = props.alignment,
      barRatio = props.barRatio,
      cornerRadius = props.cornerRadius,
      data = props.data,
      disableInlineStyles = props.disableInlineStyles,
      domain = props.domain,
      events = props.events,
      height = props.height,
      horizontal = props.horizontal,
      origin = props.origin,
      padding = props.padding,
      polar = props.polar,
      scale = props.scale,
      sharedEvents = props.sharedEvents,
      standalone = props.standalone,
      style = props.style,
      theme = props.theme,
      width = props.width,
      labels = props.labels,
      name = props.name,
      barWidth = props.barWidth,
      getPath = props.getPath;
  var initialChildProps = {
    parent: {
      horizontal: horizontal,
      domain: domain,
      scale: scale,
      width: width,
      height: height,
      data: data,
      standalone: standalone,
      name: name,
      theme: theme,
      polar: polar,
      origin: origin,
      padding: padding,
      style: style.parent
    }
  };
  return data.reduce(function (childProps, datum, index) {
    var eventKey = !Helpers.isNil(datum.eventKey) ? datum.eventKey : index;

    var _getBarPosition = getBarPosition(props, datum),
        x = _getBarPosition.x,
        y = _getBarPosition.y,
        y0 = _getBarPosition.y0,
        x0 = _getBarPosition.x0;

    var dataProps = {
      alignment: alignment,
      barRatio: barRatio,
      barWidth: barWidth,
      cornerRadius: cornerRadius,
      data: data,
      datum: datum,
      disableInlineStyles: disableInlineStyles,
      getPath: getPath,
      horizontal: horizontal,
      index: index,
      polar: polar,
      origin: origin,
      scale: scale,
      style: style.data,
      width: width,
      height: height,
      x: x,
      y: y,
      y0: y0,
      x0: x0
    };
    childProps[eventKey] = {
      data: dataProps
    };
    var text = LabelHelpers.getText(props, datum, index);

    if (text !== undefined && text !== null || labels && (events || sharedEvents)) {
      childProps[eventKey].labels = LabelHelpers.getProps(props, index);
    }

    return childProps;
  }, initialChildProps);
};