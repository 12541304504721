/**
 * Creates a compressed trie node.
 *
 * @see {@link https://en.wikipedia.org/wiki/Trie Trie on Wikipedia}
 */
export function createTrieNode() {
    return {
        children: null,
        key: undefined,
        value: undefined,
        chars: null,
        charCount: 0,
        end: false,
    };
}
function forkTrie(node) {
    var _a;
    var chars = node.chars;
    if (!chars) {
        return;
    }
    var leafNode = createTrieNode();
    node.children = (_a = {}, _a[chars[0]] = leafNode, _a);
    if (chars.length > 1) {
        leafNode.chars = chars.slice(1);
    }
    leafNode.key = node.key;
    leafNode.value = node.value;
    leafNode.charCount = node.charCount;
    leafNode.end = true;
    node.key = undefined;
    node.value = undefined;
    node.chars = null;
    node.charCount -= chars.length;
    node.end = false;
}
/**
 * Sets a new key-value pair to the trie node.
 */
export function setTrie(node, key, value) {
    var i = 0;
    while (i < key.length) {
        forkTrie(node);
        if (!node.end && !node.children) {
            break;
        }
        var children = node.children || (node.children = {});
        var charCode = key.charCodeAt(i);
        var childNode = children[charCode];
        ++i;
        if (childNode) {
            node = childNode;
            continue;
        }
        var leafNode = createTrieNode();
        children[charCode] = leafNode;
        leafNode.key = node.key;
        leafNode.charCount = node.charCount + 1;
        node = leafNode;
        break;
    }
    forkTrie(node);
    if (i !== key.length) {
        node.chars = [];
        while (i < key.length) {
            node.chars.push(key.charCodeAt(i));
            ++i;
        }
    }
    node.key = key;
    node.value = value;
    node.charCount = i;
    node.end = true;
}
/**
 * Searches for a leaf trie node that describes the longest substring from `input` starting from `offset`.
 *
 * @param node The trie with searched keys.
 * @param input The string to search for the key from the `trie`.
 * @param offset The offset in `str` to start reading substring from.
 * @param charCodeAt The callback that returns the char code at given position.
 * @returns A node or `undefined` if there's no matching key in the `trie`.
 */
export function searchTrie(node, input, offset, charCodeAt) {
    var charCount = input.length;
    var lastNode;
    forChars: for (var i = offset; i < charCount; ++i) {
        var chars = node.chars;
        if (chars) {
            var length_1 = chars.length;
            if (i + length_1 > charCount) {
                break;
            }
            for (var j = 0; j < length_1; ++i, ++j) {
                if ((charCodeAt != null ? charCodeAt(input, i) : input.charCodeAt(i)) !== chars[j]) {
                    break forChars;
                }
            }
            lastNode = node;
            break;
        }
        if (node.end) {
            lastNode = node;
        }
        var children = node.children;
        if (!children) {
            break;
        }
        var childNode = children[charCodeAt != null ? charCodeAt(input, i) : input.charCodeAt(i)];
        if (childNode) {
            if (childNode.end && !childNode.chars) {
                lastNode = childNode;
            }
            node = childNode;
            continue;
        }
        break;
    }
    return lastNode;
}
